import { Palette } from '@mui/material';
import { BarChartProps } from '@mui/x-charts';

export interface StackedBarChartLegendParams {
  palette: Palette;
  isMobile: boolean;
}

export function getStackedBarChartGoalsSeries({
  palette,
  isMobile,
}: StackedBarChartLegendParams) {
  const series: BarChartProps['series'] = [
    {
      dataKey: 'responseNotReceived',
      stack: 'a',
      label: isMobile ? 'No\n response' : 'No response',
      layout: 'horizontal',
      stackOffset: 'diverging',
      color: palette.gray.main,
    },
    {
      dataKey: 'goalsHaveChanged',
      stack: 'a',
      label: isMobile ? 'Goals have\n changed' : 'Goals have changed',
      layout: 'horizontal',
      color: palette.primary.main,
    },
    {
      dataKey: 'notAchievedYet',
      stack: 'a',
      label: isMobile ? 'Not achieved\n yet' : 'Not achieved yet',
      layout: 'horizontal',
      color: palette.error.main,
    },
    {
      dataKey: 'partiallyAchieved',
      stack: 'a',
      label: isMobile ? 'Partially\n achieved' : 'Partially achieved',
      layout: 'horizontal',
      color: palette.warning.main,
    },
    {
      dataKey: 'fullyAchieved',
      label: isMobile ? 'Fully\n achieved' : 'Fully achieved',
      stack: 'a',
      layout: 'horizontal',
      color: palette.success.main,
    },
  ];
  return series;
}
