import { AxiosError, ITypedApiResult } from '@guider-global/redux-axios-hooks';
import { ApiMeta, OrganizationIntegration } from '@guider-global/shared-types';
import {
  ITrigger,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

export interface IUseOrganizationIntegrationsParams
  extends Omit<IUseGuiderSWRParams<OrganizationIntegration>, 'url'> {}

export interface IUseOrganizationIntegrationsResults {
  organizationIntegrations: OrganizationIntegration[] | undefined;
  organizationIntegrationsMeta: ApiMeta | undefined;
  reqOrganizationIntegrations: ITrigger<OrganizationIntegration>;
  revalidateOrganizationIntegrations: () => Promise<
    ITypedApiResult<OrganizationIntegration[]> | undefined
  >;
  errorOrganizationIntegrations: AxiosError | undefined;
  isLoadingOrganizationIntegrations: boolean;
  isValidatingOrganizationIntegrations: boolean;
  isMutatingOrganizationIntegrations: boolean;
  isErrorOrganizationIntegrations: boolean;
}

export function useOrganizationIntegrations({
  waitForAuthentication = true,
  params,
  mutateOptions,
  options,
}: IUseOrganizationIntegrationsParams): IUseOrganizationIntegrationsResults {
  const {
    data,
    request,
    revalidate,
    error,
    isLoading,
    isValidating,
    isMutating,
    meta,
  } = useGuiderSWR<OrganizationIntegration>({
    waitForAuthentication,
    url: `/admin/organizationIntegrations`,
    params,
    options: {
      keepPreviousData: true,
      ...options,
    },
    mutateOptions,
  });
  return {
    organizationIntegrations: data,
    reqOrganizationIntegrations: request,
    revalidateOrganizationIntegrations: revalidate,
    errorOrganizationIntegrations: error,
    isLoadingOrganizationIntegrations: isLoading,
    isValidatingOrganizationIntegrations: isValidating,
    isErrorOrganizationIntegrations: Boolean(error),
    isMutatingOrganizationIntegrations: isMutating,
    organizationIntegrationsMeta: meta,
  };
}
