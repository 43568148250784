import { IOvertimeChart } from '@guider-global/shared-types';
import { StackedGraphLegendItem } from '@guider-global/ui';
import { Palette } from '@mui/material';

export interface GetStackedGraphLegendParams {
  guideLabelPluralized?: string;
  traineeLabelPluralized?: string;
  palette: Palette;
  isProgramSpecific?: boolean;
}

export interface StackedChartLegend extends StackedGraphLegendItem {
  key: keyof IOvertimeChart;
}

export function getStackedGraphLegend({
  guideLabelPluralized,
  traineeLabelPluralized,
  palette,
  isProgramSpecific,
}: GetStackedGraphLegendParams): StackedChartLegend[] {
  const defaultList: StackedChartLegend[] = [
    {
      key: 'guideMembershipsCreated',
      label: `${guideLabelPluralized} profiles`,
      checked: true,
      color: palette.warning.main,
    },
    {
      key: 'traineeMembershipsCreated',
      label: `${traineeLabelPluralized} profiles`,
      checked: true,
      color: palette.warning.light,
    },
    {
      key: 'activeRelationshipsCreated',
      label: 'Active Relationships',
      checked: true,
      color: palette.success.main,
    },
    {
      key: 'concludedRelationshipsCreated',
      label: 'Concluded Relationships',
      checked: true,
      color: '#C6D9D5',
    },
    {
      key: 'sessionsCreated',
      label: 'Sessions',
      checked: true,
      color: '#ADD8E6',
    },
  ];

  if (isProgramSpecific) {
    return [
      {
        key: 'membershipsCreated',
        label: 'Signed up',
        checked: true,
        color: '#008cf9',
      },
      ...defaultList,
    ];
  } else {
    return [
      {
        key: 'profilesCreated',
        label: 'Signed up',
        checked: true,
        color: '#008cf9',
      },
      ...defaultList,
    ];
  }
}
