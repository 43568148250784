import { IProgramDetails } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';
import { useAppContext } from 'context';

interface IUseAdminProgramsRequestParams extends IPaginationParams {
  organizationSlug?: string;
  localeCode?: string;
}
interface IUseAdminProgramsParams
  extends Omit<
    IUseGuiderSWRParams<
      IProgramDetails,
      IProgramDetails,
      IUseAdminProgramsRequestParams
    >,
    'url'
  > {}

export function useAdminPrograms({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminProgramsParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IProgramDetails,
      IProgramDetails,
      IUseAdminProgramsRequestParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/programs' : '/admin/programs',
      params: {
        organizationSlug: organizationSlug,
        ...params,
      },
      options: { keepPreviousData: true, ...options },
      mutateOptions,
    });
  return {
    adminPrograms: data,
    reqAdminPrograms: request,
    revalidateAdminPrograms: revalidate,
    errorAdminPrograms: error,
    isLoadingAdminPrograms: isLoading,
    isValidatingAdminPrograms: isValidating,
    isErrorAdminPrograms: Boolean(error),
  };
}
