import { IChartsOverviewTraineesByCountry } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsTraineesByCountryParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsOverviewTraineesByCountry,
      IChartsOverviewTraineesByCountry,
      IChartParams
    >,
    'url'
  > {}
export function useAdminChartsTraineesByCountry({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsTraineesByCountryParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsOverviewTraineesByCountry,
      IChartsOverviewTraineesByCountry,
      IChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'overview-trainees-by-country',
        ...params,
      },
      options: {
        ...options,
        keepPreviousData: true,
      },
      mutateOptions,
    });

  return {
    adminChartsTraineesByCountry: data,
    reqAdminChartsTraineesByCountry: request,
    revalidateAdminChartsTraineesByCountry: revalidate,
    errorAdminChartsTraineesByCountry: error,
    isLoadingAdminChartsTraineesByCountry: isLoading,
    isValidatingAdminChartsTraineesByCountry: isValidating,
    isErrorAdminChartsTraineesByCountry: Boolean(error),
  };
}
