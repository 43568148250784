import { GridValueFormatterParams } from '@mui/x-data-grid';

type GenderOptions = 'male' | 'female' | 'non_binary' | 'prefer_not_to_say';

export function getGenderLabel(sanityGenderOptions?: {
  male?: string | undefined;
  female?: string | undefined;
  non_binary?: string | undefined;
  prefer_not_to_say?: string | undefined;
}) {
  return ({ value }: GridValueFormatterParams) => {
    const typeValue = value as GenderOptions;
    return sanityGenderOptions ? sanityGenderOptions[typeValue] : value;
  };
}
