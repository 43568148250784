import { IChartsProgramPostRelationshipSurveys } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IProgramChartParams } from '../types';

interface IuseAdminChartsProgramPostRelationshipSurveysParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsProgramPostRelationshipSurveys,
      IChartsProgramPostRelationshipSurveys,
      IProgramChartParams
    >,
    'url'
  > {
  params: IProgramChartParams;
}

export function useAdminChartsProgramPostRelationshipSurveys({
  waitForAuthentication = true,
  params,
  options,
}: IuseAdminChartsProgramPostRelationshipSurveysParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsProgramPostRelationshipSurveys,
      IChartsProgramPostRelationshipSurveys,
      IProgramChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'program-post-relationship-surveys',
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
    });
  return {
    adminChartsProgramPostRelationshipSurveys: data,
    reqAdminChartsProgramPostRelationshipSurveys: request,
    revalidateAdminChartsProgramPostRelationshipSurveys: revalidate,
    errorAdminChartsProgramPostRelationshipSurveys: error,
    isLoadingAdminChartsProgramPostRelationshipSurveys: isLoading,
    isValidatingAdminChartsProgramPostRelationshipSurveys: isValidating,
    isErrorAdminChartsProgramPostRelationshipSurveys: Boolean(error),
  };
}
