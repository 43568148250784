import { ProgramRegistrationQuestion } from '@guider-global/shared-types';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { getSanityFieldColumn } from '../getSanityFieldColumn';

export interface SanityProgramFieldColumnsParams<
  Row extends GridValidRowModel = any,
> {
  fields: ProgramRegistrationQuestion[];
  customFieldId?: (fieldId: string) => string;
  customHeader?: (
    audience: 'trainee' | 'guide' | 'both',
    field: ProgramRegistrationQuestion,
    fieldId: string,
  ) => string;
  getColumnOptions?: (fieldId: string) => Partial<GridColDef<Row>>;
}

export function getSanityProgramFieldColumns<
  Row extends GridValidRowModel = any,
>({
  fields,
  customFieldId,
  customHeader,
  getColumnOptions,
}: SanityProgramFieldColumnsParams<Row>) {
  const columns = fields?.map((field) => {
    const columnOptions = getColumnOptions
      ? getColumnOptions(field?.id.current)
      : {};

    const headerName = () => {
      if (customHeader) {
        return customHeader(field.audience, field, field?.id.current);
      }
      if (field.audience === 'trainee') return field?.trainee?.question_title;
      return field?.guide?.question_title;
    };
    const column: GridColDef = {
      field: customFieldId
        ? customFieldId(field?.id.current)
        : field?.id.current,
      headerName: headerName(),

      ...columnOptions,
    };
    return getSanityFieldColumn({ field, column });
  });
  return columns;
}
