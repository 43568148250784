import { IGoal } from '@guider-global/shared-types';
import { GridCellParams, GridColDef, GridFilterItem } from '@mui/x-data-grid';
import { getGoalsStatusOptions, parsedGoalStatus } from '../';
import { Chip, CustomAutocompleteFilter } from '@guider-global/ui';

const goalsStatusOptions = getGoalsStatusOptions();

export function getGoalsReportedStatus(
  field: string,
  headerName: string,
): GridColDef<IGoal> {
  return {
    field,
    headerName,
    minWidth: 160,
    valueFormatter: ({ value }) => {
      const status = parsedGoalStatus(value);
      return status?.label;
    },
    renderCell: ({ value }) => {
      const status = parsedGoalStatus({ id: value });
      return <Chip {...status} label={`${status?.label}`} size="small" />;
    },
    filterOperators: [
      {
        label: 'select',
        value: 'includes',
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
          if (
            !filterItem.field ||
            !filterItem.value ||
            !filterItem.operator ||
            !Array.isArray(filterItem.value)
          ) {
            return null;
          }
          return (params: GridCellParams): boolean => {
            return filterItem.value.includes(params.value);
          };
        },
        InputComponent: CustomAutocompleteFilter,
        InputComponentProps: {
          options: goalsStatusOptions,
          label: 'Status Tags',
        },
        getValueAsString: (value) => {
          if (Array.isArray(value)) {
            return value.map((item) => item.id);
          }
          return value?.id;
        },
      },
    ],
  };
}
