import {
  IMembership,
  IProfileProgramMembership,
  IProfileProgramOverview,
  IRelationship,
  Program,
  ProgramVariation,
  isNotUndefined,
} from '@guider-global/shared-types';
import { getProgramMembership } from '../getProgramMembership';

export interface GetProfileProgramOverviewsParams {
  memberships: IMembership[];
  relationships: IRelationship[];
  programs: Program[];
  userProfileId: string | undefined;
  userId: string | undefined;
}

export function getProfileProgramOverviews({
  memberships,
  relationships,
  programs,
  userProfileId,
  userId,
}: GetProfileProgramOverviewsParams): IProfileProgramOverview[] {
  const membershipProgramSlugs = [
    ...new Set(
      memberships
        .map((membership) => membership.programSlug)
        .filter(isNotUndefined),
    ),
  ];

  const profileProgramOverviews: IProfileProgramOverview[] =
    membershipProgramSlugs
      .map((programSlug) => {
        const program = programs.find(
          (program) => program.metadata.id.current === programSlug,
        );
        if (!program) return undefined;

        const profileId = userProfileId;
        if (!profileId) return undefined;

        const id = `${userProfileId}:${programSlug}`;
        const programName = program.metadata.program_name;
        const programTypeSlug =
          program.program_details?.program_type?.metadata?.id?.current ?? 'n/a';
        const programTypeName =
          program.program_details?.program_type?.metadata?.cms_display_name ??
          'n/a';
        const programVariationTypeSlug = program.program_details
          ?.program_variation as ProgramVariation;
        // TODO: Replace
        const locale = 'en_GB';
        const programMemberships: IProfileProgramMembership[] = [
          getProgramMembership({
            role: 'guide',
            memberships,
            relationships,
            programSlug,
            program,
            userId,
          }),
          getProgramMembership({
            role: 'trainee',
            memberships,
            relationships,
            programSlug,
            program,
            userId,
          }),
        ].filter(isNotUndefined);

        return {
          id,
          programSlug,
          profileId,
          programName,
          programTypeSlug,
          programTypeName,
          programVariationTypeSlug,
          locale,
          memberships: programMemberships,
        };
      })
      .filter(isNotUndefined);

  return profileProgramOverviews;
}
