import { IChartsOverviewUsersByCountry } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsUsersByCountryParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsOverviewUsersByCountry,
      IChartsOverviewUsersByCountry,
      IChartParams
    >,
    'url'
  > {}
export function useAdminChartsUsersByCountry({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsUsersByCountryParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsOverviewUsersByCountry,
      IChartsOverviewUsersByCountry,
      IChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'overview-users-by-country',
        ...params,
      },
      options: {
        ...options,
        keepPreviousData: true,
      },
      mutateOptions,
    });
  return {
    adminChartsUsersByCountry: data,
    reqAdminChartsUsersByCountry: request,
    revalidateAdminChartsUsersByCountry: revalidate,
    errorAdminChartsUsersByCountry: error,
    isLoadingAdminChartsUsersByCountry: isLoading,
    isValidatingAdminChartsUsersByCountry: isValidating,
    isErrorAdminChartsUsersByCountry: Boolean(error),
  };
}
