import { useDispatch } from 'react-redux';
import {
  ISnackbar,
  clearSnackbar as clearSnackbarAction,
  setSnackbar as setSnackbarAction,
} from 'store/slices';

export function useSnackbar() {
  const dispatch = useDispatch();

  const setSnackbar = (props: Partial<ISnackbar>) => {
    dispatch(setSnackbarAction(props));
  };

  const setError = (props: Partial<ISnackbar>) => {
    dispatch(
      setSnackbarAction({
        ...props,
        show: true,
        variant: 'error',
        onClose: () => dispatch(clearSnackbarAction(true)),
      }),
    );
  };

  const setWarning = (props: Partial<ISnackbar>) => {
    dispatch(
      setSnackbarAction({
        ...props,
        show: true,
        variant: 'warning',
        onClose: () => dispatch(clearSnackbarAction(true)),
      }),
    );
  };

  const setSuccess = (props: Partial<ISnackbar>) => {
    dispatch(
      setSnackbarAction({
        ...props,
        show: true,
        variant: 'success',
        onClose: () => dispatch(clearSnackbarAction(true)),
      }),
    );
  };

  return {
    setSnackbar,
    setError,
    setWarning,
    setSuccess,
  };
}
