import { IChartsGoalFactory, IFilterModel } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';
import { GridSortModel } from '@mui/x-data-grid';
import { useAppContext } from 'context';

interface IUseAdminChartsGoalsRequestParams extends IPaginationParams {
  programSlug?: string;
  organizationSlug?: string;
  sortModel?: GridSortModel;
  filterModel?: IFilterModel;
}
interface IUseAdminChartsGoalsParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsGoalFactory,
      IChartsGoalFactory,
      IUseAdminChartsGoalsRequestParams
    >,
    'url'
  > {}

export function useAdminChartsGoals({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsGoalsParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsGoalFactory,
      IChartsGoalFactory,
      IUseAdminChartsGoalsRequestParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts/goals' : '/admin/charts/goals',
      params: {
        ...(isRootDomain && { organizationSlug }),
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsGoals: data,
    reqAdminChartsGoals: request,
    revalidateAdminChartsGoals: revalidate,
    errorAdminChartsGoals: error,
    isLoadingAdminChartsGoals: isLoading,
    isValidatingAdminChartsGoals: isValidating,
    isErrorAdminChartsGoals: Boolean(error),
  };
}
