import { IChartsOverviewGuideProfile } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsOverviewGuideProfilesParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsOverviewGuideProfile,
      IChartsOverviewGuideProfile,
      IChartParams
    >,
    'url'
  > {}

export function useAdminChartsOverviewGuideProfiles({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsOverviewGuideProfilesParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsOverviewGuideProfile,
      IChartsOverviewGuideProfile,
      IChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'overview-guide-profiles',
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsOverviewGuideProfiles: data,
    reqAdminChartsOverviewGuideProfiles: request,
    revalidateAdminChartsOverviewGuideProfiles: revalidate,
    errorAdminChartsOverviewGuideProfiles: error,
    isLoadingAdminChartsOverviewGuideProfiles: isLoading,
    isValidatingAdminChartsOverviewGuideProfiles: isValidating,
    isErrorAdminChartsOverviewGuideProfiles: Boolean(error),
  };
}
