import { IMultiSelectChipFilterOption } from '@guider-global/ui';

export function parseCountryToString(
  country: IMultiSelectChipFilterOption[] | undefined,
) {
  if (!country || country.length === 0) return undefined;
  if (country.length > 1) {
    return `${country[0].label}, ...`;
  }
  return country[0].label;
}
