import { IChartsSkillsCumulativeFactory } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsSkillsCumulativeRequestParams
  extends Omit<IChartParams, 'reportName'> {
  organizationSlug?: string;
}

interface IUseAdminChartsSkillsCumulativeParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsSkillsCumulativeFactory,
      IChartsSkillsCumulativeFactory,
      IUseAdminChartsSkillsCumulativeRequestParams
    >,
    'url'
  > {}

export function useAdminChartsSkillsCumulative({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsSkillsCumulativeParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsSkillsCumulativeFactory,
      IChartsSkillsCumulativeFactory,
      IUseAdminChartsSkillsCumulativeRequestParams
    >({
      waitForAuthentication,
      url: isRootDomain
        ? '/superadmin/charts/skills/cumulative'
        : '/admin/charts/skills/cumulative',
      params: {
        ...(isRootDomain && { organizationSlug }),
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsSkillsCumulative: data,
    reqAdminChartsSkillsCumulative: request,
    revalidateAdminChartsSkillsCumulative: revalidate,
    errorAdminChartsSkillsCumulative: error,
    isLoadingAdminChartsSkillsCumulative: isLoading,
    isValidatingAdminChartsSkillsCumulative: isValidating,
    isErrorAdminChartsSkillsCumulative: Boolean(error),
  };
}
