import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IUserProfile } from '@guider-global/shared-types';
import { adminUserProfilesSlice } from 'store/slices';
import { useAuth } from '@guider-global/auth-hooks';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle User REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IUser> hook
 * @returns ...hook - all return values via the `useRestRedux<IUserProfile>()` hook
 * @returns adminUserProfiles - array of adminUserProfiles in the RestRootState reducer
 * @returns reqAdminUserProfiles - makes requests and stores results in the adminUserProfilesReducer
 * @returns getErrorsUsers - gets API errors from the adminUserProfilesReducer
 */
export function useAdminUserProfiles({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IUserProfile, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IUserProfile, RestRootStateTypes> =
    useRestRedux<IUserProfile, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'adminUserProfiles',
      actions: adminUserProfilesSlice.actions,
      waitForAuthentication,
      accessToken,
      getSilently: false,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    adminUserProfiles: hook.getResults,
    reqAdminUserProfiles: hook.request,
    getErrorsAdminUserProfiles: hook.getErrors,
    hasResultsAdminUserProfiles: hook.hasResults,
    getMessageAdminUserProfiles: hook.getMessage,
    getCodeAdminUserProfiles: hook.getCode,
    isLoadingAdminUserProfiles: hook.isLoading,
    isErrorAdminUserProfiles: hook.isError,
    isSuccessAdminUserProfiles: hook.isSuccess,
  };
}
