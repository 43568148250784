import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IOrganization } from '@guider-global/shared-types';
import { organizationsSlice } from 'store/slices/organizationsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Organizations REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IOrganization> hook
 * @returns ...hook - all return values via the `useRestRedux<IOrganization>()` hook
 * @returns organizations - array of organizations in the RestRootState reducer
 * @returns reqOrganizations - makes requests and stores results in the organizationsReducer
 * @returns getErrorsOrganizations - gets API errors from the organizationsReducer
 */
export function useOrganizations({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IOrganization, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IOrganization, RestRootStateTypes> =
    useRestRedux<IOrganization, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'organizations',
      actions: organizationsSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    organizations: hook.getResults,
    reqOrganizations: hook.request,
    getErrorsOrganizations: hook.getErrors,
    hasResultsOrganizations: hook.hasResults,
    getMessageOrganizations: hook.getMessage,
    getCodeOrganizations: hook.getCode,
    isLoadingOrganizations: hook.isLoading,
    isErrorOrganizations: hook.isError,
    isSuccessOrganizations: hook.isSuccess,
  };
}
