import { RefObject } from 'react';

type TruncateYAxisLabelsProps = {
  label: string;
  chartRef: RefObject<HTMLDivElement>;
  maxWidth: number;
};

export const getTruncateYAxisLabels = ({
  chartRef,
  label,
  maxWidth,
}: TruncateYAxisLabelsProps): string => {
  const getMaxLabelLength = (chartRef: RefObject<HTMLDivElement>): number => {
    if (chartRef.current) {
      const chartWidth = chartRef.current.offsetWidth;
      const divisor = 10;
      return Math.floor(chartWidth / divisor);
    }
    return 10;
  };

  const truncateLabel = (label: string, maxLength: number): string => {
    return label.length > maxLength
      ? `${label.substring(0, maxLength)}...`
      : label;
  };

  const maxLength = Math.min(getMaxLabelLength(chartRef), maxWidth);
  return truncateLabel(label, maxLength);
};
