import { ExportCSVHeaders } from '@guider-global/front-end-utils';
import { IFilterModel } from '@guider-global/shared-types';
import { GridSortModel } from '@mui/x-data-grid';
import { ChartDownloaderAutoExportOptions } from 'context/ChartDownloaderContext/types';
import { useChartDownloader } from 'hooks/useChartDownloader';
import { RefObject, useCallback, useMemo } from 'react';

export interface IChartsExportOptions
  extends Omit<ChartDownloaderAutoExportOptions, 'filetype'> {
  reportPath?: string;
  reportName?: string;
  filename: string;
  programSlug?: string;
  filterModel?: IFilterModel;
  sortModel?: GridSortModel;
  chartRef?: RefObject<HTMLDivElement> | null;
}

export function useChartExportOptions({
  reportPath,
  filename,
  filterModel,
  headers,
  chartRef,
}: IChartsExportOptions) {
  const chartDownloader = useChartDownloader();
  const { createDownload, startDownload } = chartDownloader;

  const initiateDownload = useCallback(
    (filetype: 'csv' | 'json' | 'png') => {
      const downloadId = createDownload({
        reportPath,
        pageLimit: 5000,
        filterModel,
        autoExport: {
          headers: headers as ExportCSVHeaders[],
          filename,
          filetype,
          chartRef,
        },
      });
      startDownload(downloadId);
    },
    [
      createDownload,
      reportPath,
      filterModel,
      headers,
      filename,
      chartRef,
      startDownload,
    ],
  );

  const initiateCsvDownload = useCallback(() => {
    initiateDownload('csv');
  }, [initiateDownload]);

  const initiateJsonDownload = useCallback(() => {
    initiateDownload('json');
  }, [initiateDownload]);

  const initiatePngDownload = useCallback(() => {
    initiateDownload('png');
  }, [initiateDownload]);

  const exportDropdownButtons = useMemo(
    () => [
      {
        key: 'csv',
        label: 'Download as CSV',
        onClick: initiateCsvDownload,
      },
      {
        key: 'json',
        label: 'Download as JSON',
        onClick: initiateJsonDownload,
      },
      {
        key: 'png',
        label: 'Download as PNG',
        onClick: initiatePngDownload,
      },
    ],
    [initiateCsvDownload, initiateJsonDownload, initiatePngDownload],
  );

  return exportDropdownButtons;
}
