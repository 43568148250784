import { useSanityOrganizationPrograms } from '@guider-global/sanity-hooks';
import { useAppContext } from 'context';
import {
  useAdminMemberships,
  useAdminRelationships,
  useAdminUserProfiles,
} from 'hooks/admin';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProfileProgramOverviews } from './functions/getProfileProgramOverviews';

export function useProfileProgramOverviews() {
  // Params/Context
  const { userProfileId } = useParams();
  const { isRootDomain } = useAppContext();

  // User Profiles
  const { adminUserProfiles } = useAdminUserProfiles({ getSilently: false });
  const userProfile = adminUserProfiles().at(0);
  const userId = userProfile?.userId;
  const organizationSlug = userProfile?.organizationSlug;

  // Sanity - Programs
  // TODO: Find how to get programs when using root domain/superadmin
  const { getPrograms, isLoadingSanityPrograms } =
    useSanityOrganizationPrograms({
      organizationSlug,
      getSilently: organizationSlug !== undefined,
      localeCode: 'en_GB',
    });
  const programs = getPrograms();
  // Memberships
  const {
    reqAdminMemberships,
    adminMemberships: getAdminMemberships,
    isLoadingAdminMemberships: getIsLoadingAdminMemberships,
  } = useAdminMemberships({
    getSilently: false,
  });
  const [fetchedUserMemberships, setFetchedUserMemberships] = useState(false);
  const isLoadingAdminMemberships = getIsLoadingAdminMemberships();
  const memberships = getAdminMemberships();

  // Relationships
  const {
    reqAdminRelationships,
    adminRelationships: getAdminRelationships,
    isLoadingAdminRelationships: getIsLoadingAdminRelationships,
  } = useAdminRelationships({
    getSilently: false,
  });
  const [fetchedUserRelationships, setFetchedUserRelationships] =
    useState(false);
  const isLoadingAdminRelationships = getIsLoadingAdminRelationships();
  const relationships = getAdminRelationships();

  // Fetch Memberships
  useEffect(() => {
    if (fetchedUserMemberships) return;
    if (isLoadingAdminMemberships) return;
    if (!organizationSlug) return;
    if (!userProfileId) return;
    setFetchedUserMemberships(true);
    reqAdminMemberships({
      url: isRootDomain ? '/superadmin/memberships' : '/admin/memberships',
      params: {
        organizationSlug,
        profileId: userProfileId,
      },
    });
  }, [
    reqAdminMemberships,
    fetchedUserMemberships,
    isLoadingAdminMemberships,
    isRootDomain,
    organizationSlug,
    userProfileId,
  ]);

  // Fetch Relationships
  useEffect(() => {
    if (
      fetchedUserRelationships ||
      isLoadingAdminRelationships ||
      !userProfileId ||
      !organizationSlug
    ) {
      return;
    }
    setFetchedUserRelationships(true);
    reqAdminRelationships({
      url: isRootDomain ? '/superadmin/relationships' : '/admin/relationships',
      params: {
        organizationSlug,
        profileId: userProfileId,
      },
    });
  }, [
    reqAdminRelationships,
    fetchedUserRelationships,
    isLoadingAdminRelationships,
    isRootDomain,
    organizationSlug,
    userProfileId,
  ]);

  // Build profileProgramOverviews
  const profileProgramOverviews = useMemo(
    () =>
      getProfileProgramOverviews({
        memberships,
        relationships,
        programs,
        userProfileId,
        userId,
      }),
    [memberships, programs, relationships, userId, userProfileId],
  );

  const areProfileProgramOverviewsFetching =
    isLoadingAdminMemberships ||
    isLoadingAdminRelationships ||
    isLoadingSanityPrograms();

  const refetchRelationships = () => {
    reqAdminRelationships({
      url: isRootDomain ? '/superadmin/relationships' : '/admin/relationships',
      params: {
        organizationSlug,
        profileId: userProfileId,
      },
    });
  };

  const refetchMemberships = () => {
    reqAdminMemberships({
      url: isRootDomain ? '/superadmin/memberships' : '/admin/memberships',
      params: {
        organizationSlug,
        profileId: userProfileId,
      },
    });
  };

  return {
    profileProgramOverviews,
    areProfileProgramOverviewsFetching,
    refetchRelationships,
    refetchMemberships,
  };
}
