import { SanityProgramSurveySelectConfigOption } from '@guider-global/shared-types';
import {
  IMultiSelectChipFilterOption,
  getCustomGridAutocompleteFilterOperator,
} from '@guider-global/ui';
import { GridColDef } from '@mui/x-data-grid';

export interface IGetSanitySurveyColumn {
  field: {
    field_type?: 'text' | 'select' | 'scale' | 'extended_scale';
    select_config?: {
      options?: SanityProgramSurveySelectConfigOption[];
    };
  };
  column: GridColDef;
}

export function getSanitySurveyColumn({
  field,
  column,
}: IGetSanitySurveyColumn) {
  const options = field?.select_config?.options ?? [];
  const selectOptions: IMultiSelectChipFilterOption[] = options.map(
    (option) => {
      const selectChip: IMultiSelectChipFilterOption = {
        id: option._key,
        label: option.option_label ?? option.slug,
        color: 'gray',
        variant: 'outlined',
      };
      return selectChip;
    },
  );
  if (field.field_type === 'select') {
    const select: GridColDef<any, string | undefined> = {
      ...column,
      valueFormatter: ({ value: v, ...rest }) => {
        const value = Array.isArray(v) ? v.at(0) : v;
        const valueOption = options?.find(
          (option) => option.slug.current === value,
        );
        return valueOption?.option_label ?? value;
      },
      filterOperators: [
        getCustomGridAutocompleteFilterOperator({
          componentProps: {
            options: selectOptions,
          },
        }),
      ],
    };
    return select;
  }
  return column;
}
