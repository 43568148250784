import { SanityProfileField } from '@guider-global/shared-types';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { getSanityFieldColumn } from '../getSanityFieldColumn';

export interface SanityProfileFieldColumnsParams<
  Row extends GridValidRowModel = any,
> {
  fields: SanityProfileField[];
  customFieldId?: (fieldId: string) => string;
  customHeader?: (fieldLabel: string | undefined, fieldId: string) => string;
  getColumnOptions?: (fieldId: string) => Partial<GridColDef<Row>>;
}

export function getSanityProfileFieldColumns<
  Row extends GridValidRowModel = any,
>({
  fields,
  customFieldId,
  customHeader,
  getColumnOptions,
}: SanityProfileFieldColumnsParams<Row>) {
  const columns = fields?.map((field) => {
    const columnOptions = getColumnOptions
      ? getColumnOptions(field?.id.current)
      : {};
    const column: GridColDef = {
      field: customFieldId
        ? customFieldId(field?.id.current)
        : field?.id.current,
      headerName: customHeader
        ? customHeader(field.text.label, field?.id.current)
        : field.text.label,
      ...columnOptions,
    };
    return getSanityFieldColumn({ field, column });
  });
  return columns;
}
