import { IChartsSkillsOffered } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IProgramChartParams } from '../types';

interface IUseAdminChartsSkillsOfferedParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsSkillsOffered,
      IChartsSkillsOffered,
      IProgramChartParams
    >,
    'url'
  > {
  params: IProgramChartParams;
}

export function useAdminChartsSkillsOffered({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsSkillsOfferedParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsSkillsOffered,
      IChartsSkillsOffered,
      IProgramChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'skills-offered',
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsSkillsOffered: data,
    reqAdminChartsSkillsOffered: request,
    revalidateAdminChartsSkillsOffered: revalidate,
    errorAdminChartsSkillsOffered: error,
    isLoadingAdminChartsSkillsOffered: isLoading,
    isValidatingAdminChartsSkillsOffered: isValidating,
    isErrorAdminChartsSkillsOffered: Boolean(error),
  };
}
