import { IChartsSkillsSought } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IProgramChartParams } from '../types';

interface IUseAdminChartsSkillsSoughtParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsSkillsSought,
      IChartsSkillsSought,
      IProgramChartParams
    >,
    'url'
  > {
  params: IProgramChartParams;
}

export function useAdminChartsSkillsSought({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsSkillsSoughtParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IChartsSkillsSought, IChartsSkillsSought, IProgramChartParams>(
      {
        waitForAuthentication,
        url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
        params: {
          ...(isRootDomain && { organizationSlug }),
          reportName: 'skills-sought',
          ...params,
        },
        options: {
          keepPreviousData: true,
          ...options,
        },
        mutateOptions,
      },
    );
  return {
    adminChartsSkillsSought: data,
    reqAdminChartsSkillsSought: request,
    revalidateAdminChartsSkillsSought: revalidate,
    errorAdminChartsSkillsSought: error,
    isLoadingAdminChartsSkillsSought: isLoading,
    isValidatingAdminChartsSkillsSought: isValidating,
    isErrorAdminChartsSkillsSought: Boolean(error),
  };
}
