import { getCountryCodeName } from '@guider-global/front-end-utils';
import { IChartsGroupProgramTraineesFactoryResponse } from '@guider-global/shared-types';
import {
  IMultiSelectChipFilterOption,
  getCustomGridAutocompleteFilterOperator,
  getCustomGridDateFilterOperator,
} from '@guider-global/ui';
import { GridColDef } from '@mui/x-data-grid';
import { format, formatDuration } from 'date-fns';

export interface IGetGroupTraineeProfileColumnsParams {
  countryLabel?: string;
  countryOptions: IMultiSelectChipFilterOption[];
  countryEnabled?: boolean;
}

type GroupGridColumn<Value = any, FormattedValue = Value> = GridColDef<
  IChartsGroupProgramTraineesFactoryResponse,
  Value,
  FormattedValue
>;

export const getGroupTraineeProfileColumns = ({
  countryLabel = 'Country',
  countryOptions,
  countryEnabled,
}: IGetGroupTraineeProfileColumnsParams) => {
  const getCountryColumn = () => {
    if (countryEnabled) {
      const country: GroupGridColumn<string, string> = {
        field: 'country',
        headerName: countryLabel,
        valueFormatter: ({ value }) => getCountryCodeName(value) ?? value,
        minWidth: 200,
        filterOperators: [
          getCustomGridAutocompleteFilterOperator({
            componentProps: {
              options: countryOptions,
            },
          }),
        ],
      };
      return [country];
    }
    return [];
  };
  const columns: GroupGridColumn[] = [
    {
      field: 'firstName',
      headerName: 'First name',
      minWidth: 100,
      flex: 0.4,
    },
    { field: 'lastName', headerName: 'Last name', minWidth: 100, flex: 0.4 },
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 0.5 },
    ...getCountryColumn(),
    {
      field: 'active',
      headerName: 'Active Groups',
      type: 'number',
    },
    {
      field: 'past',
      headerName: 'Past Groups',
      type: 'number',
    },
    {
      field: 'sessionsAttended',
      headerName: 'Sessions Attended',
      type: 'number',
    },
    {
      field: 'totalSessionMinutes',
      headerName: 'Total attendance',
      type: 'number',
      valueFormatter: ({ value }) => {
        return formatDuration({ minutes: value }, { zero: true });
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
  ];

  return columns;
};
