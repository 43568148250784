import { IChartsOverviewTraineeProfile } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsOverviewTraineeProfilesParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsOverviewTraineeProfile,
      IChartsOverviewTraineeProfile,
      IChartParams
    >,
    'url'
  > {}

export function useAdminChartsOverviewTraineeProfiles({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsOverviewTraineeProfilesParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsOverviewTraineeProfile,
      IChartsOverviewTraineeProfile,
      IChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'overview-trainee-profiles',
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsOverviewTraineeProfiles: data,
    reqAdminChartsOverviewTraineeProfiles: request,
    revalidateAdminChartsOverviewTraineeProfiles: revalidate,
    errorAdminChartsOverviewTraineeProfiles: error,
    isLoadingAdminChartsOverviewTraineeProfiles: isLoading,
    isValidatingAdminChartsOverviewTraineeProfiles: isValidating,
    isErrorAdminChartsOverviewTraineeProfiles: Boolean(error),
  };
}
