import { IChartsGroupRelationshipsFactoryResponse } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsGroupProgramRelationshipsRequestParams
  extends IChartParams {
  programSlug: string;
  organizationSlug?: string;
}

interface IUseAdminChartsGroupProgramRelationshipsParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsGroupRelationshipsFactoryResponse,
      IChartsGroupRelationshipsFactoryResponse,
      IUseAdminChartsGroupProgramRelationshipsRequestParams
    >,
    'url'
  > {
  params: IUseAdminChartsGroupProgramRelationshipsRequestParams;
}

export function useAdminChartsGroupProgramRelationships({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsGroupProgramRelationshipsParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsGroupRelationshipsFactoryResponse,
      IChartsGroupRelationshipsFactoryResponse,
      IUseAdminChartsGroupProgramRelationshipsRequestParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts/group' : '/admin/charts/group',
      params: {
        ...(isRootDomain && { organizationSlug }),
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsGroupProgramRelationships: data,
    reqAdminChartsGroupProgramRelationships: request,
    revalidateAdminChartsGroupProgramRelationships: revalidate,
    errorAdminChartsGroupProgramRelationships: error,
    isLoadingAdminChartsGroupProgramRelationships: isLoading,
    isValidatingAdminChartsGroupProgramRelationships: isValidating,
    isErrorAdminChartsGroupProgramRelationships: Boolean(error),
  };
}
