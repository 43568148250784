import {
  SanityProgramSurveyField,
  SanityProgramSurveySelectConfigOption,
} from '@guider-global/shared-types';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { getSanitySurveyColumn } from '../getSanitySurveyColumns';

export interface SanitySurveyFieldColumnsParams<
  Row extends GridValidRowModel = any,
> {
  fields: SanityProgramSurveyField[];
  surveyFieldId?: (fieldId: string) => string;
  customHeader?: (
    audience: 'trainee' | 'guide' | 'both',
    fieldId: string,
    field: {
      field_type?: 'text' | 'select' | 'scale' | 'extended_scale';
      select_config?: {
        options?: SanityProgramSurveySelectConfigOption[];
      };
    },
  ) => string;
  getColumnOptions?: (fieldId: string) => Partial<GridColDef<Row>>;
}

export function getSanitySurveyFieldColumns<
  Row extends GridValidRowModel = any,
>({
  fields,
  surveyFieldId,
  customHeader,
  getColumnOptions,
}: SanitySurveyFieldColumnsParams<Row>) {
  const columns = fields?.map((field) => {
    const columnOptions = getColumnOptions
      ? getColumnOptions(field?.slug.current)
      : {};
    const headerName = () => {
      if (customHeader) {
        if (field.audience === 'trainee')
          return field.trainee?.trainee_question_title;
        if (field.audience === 'guide')
          return field.guide?.guide_question_title;
        if (field.audience === 'both')
          return field.trainee?.trainee_question_title;
        else return;
      } else return;
    };
    const column: GridColDef = {
      field: surveyFieldId
        ? surveyFieldId(field?.slug.current)
        : field?.slug.current,
      headerName: headerName(),

      ...columnOptions,
    };
    return getSanitySurveyColumn({ field, column });
  });
  return columns;
}
