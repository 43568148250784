import { IOrganization } from '@guider-global/shared-types';

export const getOrganizationName = (
  organizationsDetails: IOrganization[],
  organizationSlug: string,
) => {
  const organization = organizationsDetails.find(
    (organizationDetail) => organizationDetail.slug === organizationSlug,
  );
  const name = organization?.displayName;

  return name ?? organizationSlug;
};
