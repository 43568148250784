import { IChartsGoalCumulativeFactory } from '@guider-global/shared-types';

export const getGoalsResponseReceivedCount = (
  data: IChartsGoalCumulativeFactory[],
): number => {
  return data.reduce((sum, item) => {
    return (
      sum +
      item.goalsHaveChanged +
      item.notAchievedYet +
      item.partiallyAchieved +
      item.fullyAchieved
    );
  }, 0);
};
