import { IGoal } from '@guider-global/shared-types';
import { IMultiSelectChipFilterOption } from '@guider-global/ui';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { getGoalsReportedStatus } from '../';
import { getCountryColumn } from 'functions/metrics/utils';

export interface IGetGoalsColumnParams {
  guideLabel?: string;
  traineeLabel?: string;
  isSanityOrganizationCountryEnabled?: boolean;
  countryOptions: IMultiSelectChipFilterOption[];
}

export const getGoalsColumns = ({
  guideLabel,
  traineeLabel,
  isSanityOrganizationCountryEnabled,
  countryOptions,
}: IGetGoalsColumnParams) => {
  const columns: GridColDef<IGoal>[] = [
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    { field: 'userEmail', headerName: 'Email', minWidth: 200 },
    { field: 'userFirstName', headerName: 'First name' },
    { field: 'userLastName', headerName: 'Last name' },
    ...getCountryColumn({
      field: 'userCountry',
      enabled: isSanityOrganizationCountryEnabled,
      countryOptions: countryOptions,
    }),
    {
      field: 'category',
      headerName: 'Goal category',
      flex: 1,
      minWidth: 110,
    },
    {
      field: 'objective',
      headerName: 'Goal objective',
      flex: 0.8,
      minWidth: 110,
    },
    { field: 'shared', headerName: 'Shared goal' },
    getGoalsReportedStatus(
      `guideReportedStatus`,
      `${guideLabel ?? 'Guide'} reported status`,
    ),
    getGoalsReportedStatus(
      `traineeReportedStatus`,
      `${traineeLabel ?? 'Trainee'} reported status`,
    ),
  ];
  return columns;
};
