import { IOvertimeChart } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminOvertimeChartsRequestParams
  extends Pick<
    IChartParams,
    'filterModel' | 'organizationSlug' | 'page' | 'pageLimit'
  > {
  organizationSlug?: string;
  programSlug?: string;
}

interface IUseAdminOvertimeChartsParams
  extends Omit<
    IUseGuiderSWRParams<
      IOvertimeChart,
      IOvertimeChart,
      IUseAdminOvertimeChartsRequestParams
    >,
    'url'
  > {}

export function useAdminOvertimeCharts({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminOvertimeChartsParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IOvertimeChart,
      IOvertimeChart,
      IUseAdminOvertimeChartsRequestParams
    >({
      waitForAuthentication,
      url: isRootDomain
        ? '/superadmin/charts/overtime'
        : '/admin/charts/overtime',
      params: {
        ...(isRootDomain && { organizationSlug }),
        ...params,
      },
      options: { keepPreviousData: true, ...options },
    });
  return {
    adminOvertimeCharts: data,
    reqAdminOvertimeCharts: request,
    revalidateAdminOvertimeCharts: revalidate,
    errorAdminOvertimeCharts: error,
    isLoadingAdminOvertimeCharts: isLoading,
    isValidatingAdminOvertimeCharts: isValidating,
    isErrorAdminOvertimeCharts: Boolean(error),
  };
}
