import { IChartsOverviewUsersByCustomFields } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IuseAdminChartsUsersByCustomFieldsParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsOverviewUsersByCustomFields,
      IChartsOverviewUsersByCustomFields,
      IChartParams
    >,
    'url'
  > {}
export function useAdminChartsUsersByCustomFields({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IuseAdminChartsUsersByCustomFieldsParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsOverviewUsersByCustomFields,
      IChartsOverviewUsersByCustomFields,
      IChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'overview-users-by-customfields',
        ...params,
      },
      options: {
        ...options,
        keepPreviousData: true,
      },
      mutateOptions,
    });
  return {
    adminChartsUsersByCustomFields: data,
    reqAdminChartsUsersByCustomFields: request,
    revalidateAdminChartsUsersByCustomFields: revalidate,
    errorAdminChartsUsersByCustomFields: error,
    isLoadingAdminChartsUsersByCustomFields: isLoading,
    isValidatingAdminChartsUsersByCustomFields: isValidating,
    isErrorAdminChartsUsersByCustomFields: Boolean(error),
  };
}
