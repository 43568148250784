import { IChartsProgramPostSessionSurveys } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IProgramChartParams } from '../types';

interface IuseAdminChartsProgramPostSessionSurveysParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsProgramPostSessionSurveys,
      IChartsProgramPostSessionSurveys,
      IProgramChartParams
    >,
    'url'
  > {
  params: IProgramChartParams;
}

export function useAdminChartsProgramPostSessionSurveys({
  waitForAuthentication = true,
  params,
  options,
}: IuseAdminChartsProgramPostSessionSurveysParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsProgramPostSessionSurveys,
      IChartsProgramPostSessionSurveys,
      IProgramChartParams
    >({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'program-post-session-surveys',
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
    });
  return {
    adminChartsProgramPostSessionSurveys: data,
    reqAdminChartsProgramSurveys: request,
    revalidateAdminChartsProgramPostSessionSurveys: revalidate,
    errorAdminChartsProgramPostSessionSurveys: error,
    isLoadingAdminChartsProgramPostSessionSurveys: isLoading,
    isValidatingAdminChartsProgramPostSessionSurveys: isValidating,
    iserrorAdminChartsProgramPostSessionSurveys: Boolean(error),
  };
}
