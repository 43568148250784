import {
  IMembership,
  IProfile,
  IProfileProgramRelationshipProfile,
  IRelationship,
} from '@guider-global/shared-types';

export interface GetRelationshipProfilesParams {
  relationships: IRelationship[];
  membership: IMembership;
  userId: string | undefined;
}

export function getRelationshipProfiles({
  relationships,
  membership,
  userId,
}: GetRelationshipProfilesParams) {
  const membershipRelationships = relationships.filter((relationship) => {
    if (!relationship.programSlug) return false;
    if (relationship.programSlug !== membership.programSlug) return false;
    if (!userId) return false;
    if (membership.role === 'guide') {
      const guideProfiles = relationship.guideProfiles as IProfile[];
      if (!guideProfiles.some((profile) => profile.userId === userId))
        return false;
    }
    if (membership.role === 'trainee') {
      const traineeProfiles = relationship.traineeProfiles as IProfile[];
      if (!traineeProfiles.some((profile) => profile.userId === userId))
        return false;
    }
    return true;
  });

  const profiles = membershipRelationships
    .flatMap((relationship) =>
      membership.role === 'guide'
        ? (relationship.traineeProfiles as IProfile[])
        : (relationship.guideProfiles as IProfile[]),
    )
    .reduce<IProfile[]>(
      (uniqueProfiles, profile) =>
        uniqueProfiles.some((uniqueProfile) => profile.id === uniqueProfile.id)
          ? uniqueProfiles
          : [...uniqueProfiles, profile],
      [],
    );

  const counterpartyRole = membership.role === 'guide' ? 'trainee' : 'guide';
  const relationshipProfiles: IProfileProgramRelationshipProfile[] = profiles
    .flatMap((profile) =>
      membershipRelationships
        .filter((relationship) => {
          switch (counterpartyRole) {
            case 'guide': {
              const guideProfiles = relationship.guideProfiles as IProfile[];
              return guideProfiles.some(
                (guideProfile) => profile.id === guideProfile.id,
              );
            }
            case 'trainee': {
              const traineeProfiles =
                relationship.traineeProfiles as IProfile[];
              return traineeProfiles.some(
                (traineeProfile) => profile.id === traineeProfile.id,
              );
            }
            default:
              return false;
          }
        })
        .map((relationship) => ({ relationship, profile })),
    )
    .map<IProfileProgramRelationshipProfile>(({ relationship, profile }) => {
      const id = profile.id;
      const relationshipId = relationship.id;
      const title = relationship.title;
      const displayName = profile.displayName;
      const picture = profile.picture;
      const user = relationship.users?.find(
        (user) => user.id === profile.userId,
      );
      const auth0Picture = user?.picture;
      const createdAt = relationship.createdAt;
      const isConcluded = relationship.isConcluded;
      const sessionCount = (relationship.sessions ?? []).filter(
        (session) => session.isArchived !== true,
      ).length;
      return {
        id,
        relationshipId,
        displayName,
        picture,
        auth0Picture,
        createdAt,
        isConcluded,
        sessionCount,
        title,
      };
    });

  return relationshipProfiles;
}
