import { IDateFilterValue } from '@guider-global/ui';
import { format } from 'date-fns';

export function parseDateFilterToString(
  dateRange: IDateFilterValue | undefined,
) {
  if (!dateRange) return undefined;
  const to = dateRange.to ? format(dateRange.to, 'dd/MM/yyyy') : 'DD/MM/YYYY';
  const from = dateRange.from
    ? format(dateRange.from, 'dd/MM/yyyy')
    : 'DD/MM/YYYY';
  return `${from} - ${to}`;
}
