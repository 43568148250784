import {
  SanitySelectOption,
  SelectOptionPrograms,
} from '@guider-global/shared-types';
import {
  IMultiSelectChipFilterOption,
  getCustomGridAutocompleteFilterOperator,
} from '@guider-global/ui';
import { GridColDef } from '@mui/x-data-grid';

export interface IGetSanityFieldColumn {
  field: {
    type?: 'select' | 'free-text' | 'multi-select';
    options?: SanitySelectOption[] | SelectOptionPrograms[];
  };
  column: GridColDef;
}

export function getSanityFieldColumn({ field, column }: IGetSanityFieldColumn) {
  const options = field.options ?? [];
  const selectOptions: IMultiSelectChipFilterOption[] = options.map(
    (option) => {
      const selectChip: IMultiSelectChipFilterOption = {
        id: option.id.current,
        label: option.label ?? option.id.current,
        color: 'gray',
        variant: 'outlined',
      };
      return selectChip;
    },
  );
  if (field.type === 'select') {
    const select: GridColDef<any, string | undefined, string | undefined> = {
      ...column,
      valueFormatter: ({ value: v }) => {
        const value = Array.isArray(v) ? v.at(0) : v;
        const valueOption = options?.find(
          (option) => option.id.current === value,
        );
        return valueOption?.label ?? value;
      },
      filterOperators: [
        getCustomGridAutocompleteFilterOperator({
          componentProps: {
            options: selectOptions,
          },
        }),
      ],
    };
    return select;
  }
  if (field.type === 'multi-select') {
    const multiSelect: GridColDef<
      any,
      string[] | undefined,
      string | undefined
    > = {
      ...column,
      valueFormatter: ({ value, ...rest }) => {
        if (typeof value === 'string') {
          return value;
        }
        const values = value ?? [];
        const valueOptions =
          options?.filter(
            (option) => values?.find((value) => option.id.current === value),
          ) ?? [];
        return valueOptions?.map((option) => option.label).join(', ');
      },
      filterOperators: [
        getCustomGridAutocompleteFilterOperator({
          componentProps: {
            options: selectOptions,
          },
        }),
      ],
    };
    return multiSelect;
  }
  return column;
}
