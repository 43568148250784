import { useSessionStorageResults } from '@guider-global/front-end-utils';
import { useSessionStorage } from '@guider-global/front-end-utils';
import { SharedSessionStorageKeys } from '@guider-global/shared-types';

export enum AdminSessionStorageKeys {}

export const SessionStorageKeys = {
  ...AdminSessionStorageKeys,
  ...SharedSessionStorageKeys,
} as const;

export function useSessionStorageTyped<P>(
  key: (typeof SessionStorageKeys)[keyof typeof SessionStorageKeys],
  defaultValue?: P,
): useSessionStorageResults<P> {
  return useSessionStorage<P, typeof SessionStorageKeys>(key, defaultValue);
}
