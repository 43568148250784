import { IChartsGroupProgramTraineesFactoryResponse } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsGroupProgramTraineeProfilesRequestParams
  extends IChartParams {
  programSlug: string;
  organizationSlug?: string;
}

interface IUseAdminChartsGroupProgramTraineeProfilesParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsGroupProgramTraineesFactoryResponse,
      IChartsGroupProgramTraineesFactoryResponse,
      IUseAdminChartsGroupProgramTraineeProfilesRequestParams
    >,
    'url'
  > {
  params: IUseAdminChartsGroupProgramTraineeProfilesRequestParams;
}

export function useAdminChartsGroupProgramTraineeProfiles({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsGroupProgramTraineeProfilesParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsGroupProgramTraineesFactoryResponse,
      IChartsGroupProgramTraineesFactoryResponse,
      IUseAdminChartsGroupProgramTraineeProfilesRequestParams
    >({
      waitForAuthentication,
      url: isRootDomain
        ? '/superadmin/charts/group/trainee-profiles'
        : '/admin/charts/group/trainee-profiles',
      params: {
        ...(isRootDomain && { organizationSlug }),
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsGroupProgramTraineeProfiles: data,
    reqAdminChartsGroupProgramTraineeProfiles: request,
    revalidateAdminChartsGroupProgramTraineeProfiles: revalidate,
    errorAdminChartsGroupProgramTraineeProfiles: error,
    isLoadingAdminChartsGroupProgramTraineeProfiles: isLoading,
    isValidatingAdminChartsGroupProgramTraineeProfiles: isValidating,
    isErrorAdminChartsGroupProgramTraineeProfiles: Boolean(error),
  };
}
