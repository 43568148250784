import { IChartsSessions } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsGroupProgramSessionsRequestParams
  extends IChartParams {
  programSlug: string;
  organizationSlug?: string;
}

interface IUseAdminChartsGroupProgramSessionsParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsSessions,
      IChartsSessions,
      IUseAdminChartsGroupProgramSessionsRequestParams
    >,
    'url'
  > {
  params: IUseAdminChartsGroupProgramSessionsRequestParams;
}

export function useAdminChartsGroupProgramSessions({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsGroupProgramSessionsParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsSessions,
      IChartsSessions,
      IUseAdminChartsGroupProgramSessionsRequestParams
    >({
      waitForAuthentication,
      url: isRootDomain
        ? '/superadmin/charts/group/sessions'
        : '/admin/charts/group/sessions',
      params: {
        ...(isRootDomain && { organizationSlug }),
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsGroupProgramSessions: data,
    reqAdminChartsGroupProgramSessions: request,
    revalidateAdminChartsGroupProgramSessions: revalidate,
    errorAdminChartsGroupProgramSessions: error,
    isLoadingAdminChartsGroupProgramSessions: isLoading,
    isValidatingAdminChartsGroupProgramSessions: isValidating,
    isErrorAdminChartsGroupProgramSessions: Boolean(error),
  };
}
