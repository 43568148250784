import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IMembership } from '@guider-global/shared-types';
import { adminMembershipsSlice } from 'store/slices';
import { useAuth } from '@guider-global/auth-hooks';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle User REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IUser> hook
 * @returns ...hook - all return values via the `useRestRedux<IMembership>()` hook
 * @returns adminMemberships - array of adminMemberships in the RestRootState reducer
 * @returns reqAdminMemberships - makes requests and stores results in the adminMembershipsReducer
 * @returns getErrorsUsers - gets API errors from the adminMembershipsReducer
 */
export function useAdminMemberships({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IMembership, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IMembership, RestRootStateTypes> =
    useRestRedux<IMembership, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'adminMemberships',
      actions: adminMembershipsSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    adminMemberships: hook.getResults,
    reqAdminMemberships: hook.request,
    getErrorsAdminMemberships: hook.getErrors,
    hasResultsAdminMemberships: hook.hasResults,
    getMessageAdminMemberships: hook.getMessage,
    getCodeAdminMemberships: hook.getCode,
    isLoadingAdminMemberships: hook.isLoading,
    isErrorAdminMemberships: hook.isError,
    isSuccessAdminMemberships: hook.isSuccess,
  };
}
