import { getCountryCodeName } from '@guider-global/front-end-utils';
import { SanityPersonalDetails } from '@guider-global/shared-types';
import {
  IMultiSelectChipFilterOption,
  getCustomGridAutocompleteFilterOperator,
} from '@guider-global/ui';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';

export interface IGetCountryColumnParams {
  field: string;
  enabled?: boolean;
  sanityOrganizationPersonalDetails?: SanityPersonalDetails;
  countryOptions: IMultiSelectChipFilterOption[];
}

export const getCountryColumn = <
  Row extends GridValidRowModel = any,
  Value extends string = string,
>({
  field,
  enabled,
  sanityOrganizationPersonalDetails,
  countryOptions,
}: IGetCountryColumnParams) => {
  if (enabled) {
    const country: GridColDef<Row, Value, string | undefined> = {
      field,
      headerName:
        sanityOrganizationPersonalDetails?.country.custom_label ?? 'Country',
      valueFormatter: ({ value }) => {
        return value && getCountryCodeName(value);
      },
      type: 'string',
      minWidth: 200,
      filterOperators: [
        getCustomGridAutocompleteFilterOperator({
          componentProps: {
            options: countryOptions,
          },
        }),
      ],
    };
    return [country];
  }
  return [];
};
