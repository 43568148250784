import {
  ICustomField,
  IMembership,
  IProfileProgramCustomField,
  Program,
} from '@guider-global/shared-types';

export interface GetProgramFieldsParams {
  membership: IMembership;
  program: Program;
}

export function getProgramFields({
  membership,
  program,
}: GetProgramFieldsParams): IProfileProgramCustomField[] {
  const programFields = (
    membership.programFields as ICustomField[]
  ).map<IProfileProgramCustomField>((programField) => {
    const { id, fieldSlug, fieldType, value } = programField;
    const question = program.registration?.registration_questions?.find(
      (question) => question.id.current === programField.fieldSlug,
    );
    const label = question?.text?.input_label ?? 'N/A';
    const assistiveText = question?.text?.input_assistive_text ?? undefined;
    const options = (question?.options ?? []).map((option) => ({
      slug: option.id.current,
      label: option.label ?? 'N/A',
    }));

    return {
      id,
      label,
      assistiveText,
      fieldSlug,
      fieldType,
      value,
      options,
    };
  });
  return programFields;
}
