import { IMultiSelectChipFilterOption } from '@guider-global/ui';

export function getGoalsStatusOptions(): IMultiSelectChipFilterOption[] {
  return [
    {
      id: 'fully',
      label: 'Fully',
      color: 'success',
    },
    {
      id: 'no_response',
      label: 'No response',
      color: 'gray',
    },
    {
      id: 'survey_not_sent',
      label: 'Survey not sent',
      color: 'gray',
    },
    {
      id: 'not_at_all',
      label: 'Not at all',
      color: 'error',
    },
    {
      id: 'partially',
      label: 'Partially',
      color: 'warning',
    },
    {
      id: 'goals_have_changed',
      label: 'Goals have changed',
      color: 'primary',
    },
  ];
}

export type GoalsStatusId =
  | 'fully'
  | 'no_response'
  | 'active'
  | 'survey_not_sent'
  | 'not_at_all'
  | 'partially'
  | 'goals_have_changed';

export function parsedGoalStatus({ id }: { id: GoalsStatusId }) {
  const statuses = getGoalsStatusOptions();

  return statuses.find((status) => status.id === id);
}
