import { IChartsUser } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsUsersParams
  extends Omit<
    IUseGuiderSWRParams<IChartsUser, IChartsUser, IChartParams>,
    'url'
  > {}
export function useAdminChartsUsers({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsUsersParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IChartsUser, IChartsUser>({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'users',
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });

  return {
    adminChartsUsers: data,
    reqAdminChartsUsers: request,
    revalidateAdminChartsUsers: revalidate,
    errorAdminChartsUsers: error,
    isLoadingAdminChartsUsers: isLoading,
    isValidatingAdminChartsUsers: isValidating,
    isErrorAdminChartsUsers: Boolean(error),
  };
}
