import { IOrganization } from '@guider-global/shared-types';
import { GridColDef } from '@mui/x-data-grid';
import { getOrganizationName } from 'functions';

export const getOrganizationColumn = (
  organizationsDetails: IOrganization[],
  isRootDomain: boolean,
) => {
  const column: GridColDef[] = [];

  if (isRootDomain) {
    column.push({
      field: 'organizationSlug',
      headerName: 'Organization',
      valueGetter: (value) =>
        getOrganizationName(organizationsDetails, value.value),
    });
  }
  return column;
};
