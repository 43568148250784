import { IChartsSessions } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IProgramChartParams } from '../types';

interface IUseAdminChartsSessionsParams
  extends Omit<
    IUseGuiderSWRParams<IChartsSessions, IChartsSessions, IProgramChartParams>,
    'url'
  > {
  params: IProgramChartParams;
}

export function useAdminChartsSessions({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsSessionsParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IChartsSessions, IChartsSessions, IProgramChartParams>({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/charts' : '/admin/charts',
      params: {
        ...(isRootDomain && { organizationSlug }),
        reportName: 'sessions',
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsSessions: data,
    reqAdminChartsSessions: request,
    revalidateAdminChartsSessions: revalidate,
    errorAdminChartsSessions: error,
    isLoadingAdminChartsSessions: isLoading,
    isValidatingAdminChartsSessions: isValidating,
    isErrorAdminChartsSessions: Boolean(error),
  };
}
