import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IRelationship } from '@guider-global/shared-types';
import { adminManualMatchSlice } from 'store/slices';
import { useAuth } from '@guider-global/auth-hooks';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle User REST API requests connected the redux store
 *
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IUser> hook
 * @returns ...hook - all return values via the `useRestRedux<IRelationship>()` hook
 * @returns adminManualMatch - array of adminManualMatch in the RestRootState reducer
 * @returns reqAdminManualMatch - makes requests and stores results in the adminManualMatchReducer
 * @returns getErrorsUsers - gets API errors from the adminManualMatchReducer
 */
export function useAdminManualMatch({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IRelationship, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IRelationship, RestRootStateTypes> =
    useRestRedux<IRelationship, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'adminManualMatch',
      actions: adminManualMatchSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    adminManualMatch: hook.getResults,
    reqAdminManualMatch: hook.request,
    getErrorsAdminManualMatch: hook.getErrors,
    hasResultsAdminManualMatch: hook.hasResults,
    getMessageAdminManualMatch: hook.getMessage,
    getCodeAdminManualMatch: hook.getCode,
    isLoadingAdminManualMatch: hook.isLoading,
    isErrorAdminManualMatch: hook.isError,
    isSuccessAdminManualMatch: hook.isSuccess,
  };
}
