import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { IMatchV2 } from '@guider-global/shared-types';
import { useAuth } from '@guider-global/auth-hooks';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useDatadogContext } from '@guider-global/datadog';
import { adminMatchesV2Slice } from 'store/slices';

export function useAdminMatchesV2({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IMatchV2, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IMatchV2, RestRootStateTypes> = useRestRedux<
    IMatchV2,
    RestRootState,
    RestRootStateTypes,
    RootState
  >({
    ...args,
    resultDataName: 'adminMatchesV2',
    actions: adminMatchesV2Slice.actions,
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
    traceId: sessionId ?? '',
  });
  return {
    ...hook,
    adminMatchesV2: hook.getResults,
    reqAdminMatchesV2: hook.request,
    getErrorsAdminMatchesV2: hook.getErrors,
    hasResultsAdminMatchesV2: hook.hasResults,
    getMessageAdminMatchesV2: hook.getMessage,
    getCodeAdminMatchesV2: hook.getCode,
    isLoadingAdminMatchesV2: hook.isLoading,
    isErrorAdminMatchesV2: hook.isError,
    isSuccessAdminMatchesV2: hook.isSuccess,
  };
}
