import { IFilterModel, KPIs } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';
import { useAppContext } from 'context';

interface IUseAdminKPIsGroupProgramRequestParams extends IPaginationParams {
  kpiName: 'group';
  organizationSlug?: string;
  programSlug: string;
  filterModel?: IFilterModel;
}

interface IUseAdminKPIsGroupProgramParams
  extends Omit<
    IUseGuiderSWRParams<
      KPIs,
      KPIs,
      Omit<IUseAdminKPIsGroupProgramRequestParams, 'kpiName'>
    >,
    'url'
  > {
  params: Omit<IUseAdminKPIsGroupProgramRequestParams, 'kpiName'>;
}

export function useAdminKPIsGroupProgram({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminKPIsGroupProgramParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<KPIs, KPIs, IUseAdminKPIsGroupProgramRequestParams>({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/kpis' : '/admin/kpis',
      params: {
        ...(isRootDomain && { organizationSlug }),
        ...params,
        kpiName: 'group',
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminKPIsGroupProgram: data,
    reqAdminKPIsGroupProgram: request,
    revalidateAdminKPIsGroupProgram: revalidate,
    errorAdminKPIsGroupProgram: error,
    isLoadingAdminKPIsGroupProgram: isLoading,
    isValidatingAdminKPIsGroupProgram: isValidating,
    isErrorAdminKPIsGroupProgram: Boolean(error),
  };
}
