import { IChartsGoalCumulativeFactory } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';
import { useAppContext } from 'context';
import { IChartParams } from '../types';

interface IUseAdminChartsGoalsCumulativeRequestParams
  extends Omit<IChartParams, 'reportName'> {
  organizationSlug?: string;
}

interface IUseAdminChartsGoalsCumulativeParams
  extends Omit<
    IUseGuiderSWRParams<
      IChartsGoalCumulativeFactory,
      IChartsGoalCumulativeFactory,
      IUseAdminChartsGoalsCumulativeRequestParams
    >,
    'url'
  > {}

export function useAdminChartsGoalsCumulative({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminChartsGoalsCumulativeParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IChartsGoalCumulativeFactory,
      IChartsGoalCumulativeFactory,
      IUseAdminChartsGoalsCumulativeRequestParams
    >({
      waitForAuthentication,
      url: isRootDomain
        ? '/superadmin/charts/goals/cumulative'
        : '/admin/charts/goals/cumulative',
      params: {
        ...(isRootDomain && { organizationSlug }),
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminChartsGoalsCumulative: data,
    reqAdminChartsGoalsCumulative: request,
    revalidateAdminChartsGoalsCumulative: revalidate,
    errorAdminChartsGoalsCumulative: error,
    isLoadingAdminChartsGoalsCumulative: isLoading,
    isValidatingAdminChartsGoalsCumulative: isValidating,
    isErrorAdminChartsGoalsCumulative: Boolean(error),
  };
}
