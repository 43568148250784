import {
  IMembership,
  IProfileProgramMembership,
  IRelationship,
  Program,
} from '@guider-global/shared-types';
import { getProgramFields } from '../getProgramFields';
import { getSkills } from '../getSkills';
import { getRelationshipProfiles } from '../getRelationshipProfiles';

export interface GetProgramMembershipParams {
  role: 'guide' | 'trainee';
  memberships: IMembership[];
  relationships: IRelationship[];
  programSlug: string;
  program: Program;
  userId: string | undefined;
}

export function getProgramMembership({
  role,
  memberships,
  relationships,
  programSlug,
  program,
  userId,
}: GetProgramMembershipParams): IProfileProgramMembership | undefined {
  const membership = memberships.find((membership) => {
    if (membership.programSlug !== programSlug) return false;
    if (!userId) return false;
    if (membership.userId !== userId) return false;
    if (membership.role !== role) return false;
    return true;
  });
  if (!membership) return undefined;

  const commonProgramTypeText =
    program.program_details?.program_type?.program_type_text.common;
  if (!commonProgramTypeText) return undefined;

  const id = membership.id;
  const createdAt = membership.createdAt as Date;
  const otherRole = role === 'trainee' ? 'guide' : 'trainee';
  const roleLabel = commonProgramTypeText[role]?.singular;
  const otherRoleLabel = commonProgramTypeText[otherRole]?.singular;
  const isPublished = membership.isPublished ?? false;
  const isBlocked = membership.isBlocked ?? false;
  const requiresApproval = membership.requiresApproval ?? false;
  const programFields = getProgramFields({
    membership: membership,
    program,
  });

  const skillsText = program.registration?.skills?.text;
  const skillsLabel = skillsText?.input_label ?? 'Skills';
  const skillsAssistiveText = skillsText?.input_assistive_text;
  const skills = getSkills({
    membership: membership,
    program,
  });

  const fallbackRelationshipProfilesLabel =
    otherRole === 'guide' ? 'Mentors' : 'Mentees';
  const relationshipProfilesLabel =
    commonProgramTypeText[otherRole]?.pluralized ??
    fallbackRelationshipProfilesLabel;
  const relationshipProfiles = getRelationshipProfiles({
    relationships,
    membership,
    userId,
  });

  if (!roleLabel || !otherRoleLabel) return undefined;

  const profileProgramMembership: IProfileProgramMembership = {
    id,
    role,
    roleLabel,
    otherRoleLabel,
    isPublished,
    isBlocked,
    requiresApproval,
    programFields,
    skillsLabel,
    skillsAssistiveText,
    skills,
    createdAt,
    relationshipProfilesLabel,
    relationshipProfiles,
  };
  return profileProgramMembership;
}
