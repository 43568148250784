import { Palette } from '@mui/material';
import { BarChartProps } from '@mui/x-charts';

export interface StackedBarChartSeriesParams {
  palette: Palette;
  isMobile: boolean;
}

export function getStackedBarChartSkillsSeries({
  palette,
  isMobile,
}: StackedBarChartSeriesParams) {
  const series: BarChartProps['series'] = [
    {
      dataKey: 'responseNotReceived',
      stack: 'a',
      label: isMobile ? 'No\n response' : 'No response',
      layout: 'horizontal',
      stackOffset: 'diverging',
      color: palette.gray.main,
    },
    {
      dataKey: 'notWorkingOnSkill',
      label: isMobile
        ? 'Not working\n on this skill\n at the moment'
        : 'Not working on this skill\n at the moment',
      stack: 'a',
      layout: 'horizontal',
      color: palette.primary.main,
    },
    {
      dataKey: 'notImproved',
      stack: 'a',
      label: isMobile ? 'Not\n improved' : 'Not improved',
      layout: 'horizontal',
      color: palette.error.main,
    },
    {
      dataKey: 'somewhatImproved',
      stack: 'a',
      label: isMobile ? 'Somewhat \n improved' : 'Somewhat improved',
      layout: 'horizontal',
      color: palette.warning.main,
    },
    {
      dataKey: 'vastlyImproved',
      stack: 'a',
      label: isMobile ? 'Vastly\n improved' : 'Vastly improved',
      layout: 'horizontal',
      color: palette.success.main,
    },
  ];
  return series;
}
