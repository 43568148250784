import { IFilterModel, KPIs } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';
import { useAppContext } from 'context';

interface IUseAdminKPIsOrganizationRequestParams extends IPaginationParams {
  kpiName: 'organization';
  organizationSlug?: string;
  filterModel?: IFilterModel;
}

interface IUseAdminKPIsOrganizationParams
  extends Omit<
    IUseGuiderSWRParams<
      KPIs,
      KPIs,
      Omit<IUseAdminKPIsOrganizationRequestParams, 'kpiName'>
    >,
    'url'
  > {}

export function useAdminKPIsOrganization({
  waitForAuthentication = true,
  params,
  options,
  mutateOptions,
}: IUseAdminKPIsOrganizationParams) {
  const { isRootDomain, organizationSlug } = useAppContext();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<KPIs, KPIs, IUseAdminKPIsOrganizationRequestParams>({
      waitForAuthentication,
      url: isRootDomain ? '/superadmin/kpis' : '/admin/kpis',
      params: {
        ...(isRootDomain && { organizationSlug }),
        kpiName: 'organization',
        ...params,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
      mutateOptions,
    });
  return {
    adminKPIsOrganization: data,
    reqAdminKPIsOrganization: request,
    revalidateAdminKPIsOrganization: revalidate,
    errorAdminKPIsOrganization: error,
    isLoadingAdminKPIsOrganization: isLoading,
    isValidatingAdminKPIsOrganization: isValidating,
    isErrorAdminKPIsOrganization: Boolean(error),
  };
}
