import {
  GridLogicOperator,
  IFilterItem,
  IFilterModel,
} from '@guider-global/shared-types';
/*
 * Currently Multi filtering is prevented on community version of MUI
 */

export function addFilterItem(
  model: IFilterModel | undefined,
  filter: IFilterItem,
  enableMultiFilter: boolean = false,
): IFilterModel | undefined {
  const logicOperator = model?.logicOperator ?? GridLogicOperator.And;

  if (!enableMultiFilter) {
    if (!filter.value) {
      return {
        items: [],
        logicOperator,
      };
    }
    return {
      items: [filter],
      logicOperator,
    };
  }

  const items = model?.items ?? [];
  const existingFilter = items.find((item) => item.field === filter.field);
  const otherFilters = items.filter((item) => item.field !== filter.field);
  if (existingFilter) {
    if (!filter.value) {
      if (otherFilters.length === 0) {
        return {
          items: [],
          logicOperator,
        };
      }
      return {
        items: otherFilters,
        logicOperator,
      };
    }
    const updatedFilters = items.map((item) => {
      if (item.field === filter.field) {
        return filter;
      }
      return item;
    });

    return {
      items: updatedFilters,
      logicOperator,
    };
  }

  return {
    items: [...items, filter],
    logicOperator,
  };
}
