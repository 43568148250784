import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import {
  IUseRestReduxResult,
  RestReduxHook,
  useRestRedux,
} from '@guider-global/redux-axios-hooks';
import { IDeletable } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { adminDeleteUserSlice } from 'store/slices';

export function useAdminDeleteUser({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<IDeletable, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({ waitForAuthentication });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<IDeletable, RestRootStateTypes> =
    useRestRedux<IDeletable, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'adminDeleteUser',
      actions: adminDeleteUserSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });

  return {
    ...hook,
    adminDeleteUser: hook.getResults,
    reqAdminDeleteUser: hook.request,
    getErrorsAdminDeleteUser: hook.getErrors,
    hasResultsAdminDeleteUser: hook.hasResults,
    getMessageAdminDeleteUser: hook.getMessage,
    getCodeaAdminDeleteUser: hook.getCode,
    isLoadingAdminDeleteUser: hook.isLoading,
    isErrorAdminDeleteUser: hook.isError,
    isSuccessAdminDeleteUser: hook.isSuccess,
  };
}
